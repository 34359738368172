<script setup lang="ts">
const { notifications, removeOne } = useNotifications();
</script>
<template>
    <div class="sticky top-0 h-0 z-[9999]">
        <div class="container relative">
            <div
                data-testid="notification-container"
                class="absolute right-4 sm:right-0 top-36 lg:top-18 max-h-fit"
            >
                <ClientOnly>
                    <LayoutNotification
                        v-for="notification in notifications"
                        :key="notification.id"
                        :notification="notification"
                        @click:close="removeOne(notification.id)"
                    />
                </ClientOnly>
            </div>
        </div>
    </div>
</template>
